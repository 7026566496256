<template>
  <div class="header">
    <h1>{{ this.$t("mypage.point") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/point">{{
          this.$t("mypage.point")
        }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <div class="search_wrap">
      <div class="date">
        <input type="date" v-model="this.search_start_date" /><span>-</span
        ><input type="date" v-model="this.search_end_date" />
        <button class="button middle red search" @click="click_search">
          {{ this.$t("common.search") }}
        </button>
      </div>
    </div>

    <div class="point_info">
      <div class="title">{{ this.$t("order.own_point") }}</div>
      <div class="value mono">{{ this.common.number_format(this.point) }}</div>
    </div>

    <ul class="point_list">
      <li class="empty" v-if="this.point_data.length <= 0">
        {{ this.$t("common.no_data") }}
      </li>
      <li v-for="(value, index) in this.point_data" :key="index">
        <div class="delta mono">
          {{ value.point_delta >= 0 ? "+" : ""
          }}{{ this.common.number_format(value.point_delta) }}
        </div>
        <div class="info">
          <div class="date">
            {{
              this.common.date_long_format(
                this.common.date_parse(value.history_date)
              )
            }}
          </div>
          <div class="desc">
            {{ this.convert_history_action_text(value.history_action) }}
          </div>
        </div>
      </li>
    </ul>

    <button
      class="button outline small sharp compact more"
      v-if="this.point_data.length && !this.data_fully_loaded"
      @click="load_point_data"
    >
      {{ this.$t("common.load_more") }}
    </button>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "MyPoint",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.mypoint"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.mypoint"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_start_date: "",
      search_end_date: "",
      search_page: 1,
      point_data: [],
      point: 0,
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.load_user_data();
    this.load_point_data();
  },
  methods: {
    load_point_data: function () {
      if (!this.storage.is_logged_in()) return;

      // 데이터가 이미 다 로드되었으면 더이상 진행안함.
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/point/history",
          {
            user_id: self.storage.get_user_id(),
            history_start_date: self.search_start_date,
            history_end_date: self.search_end_date,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          // 처음 로드할 경우 데이터 초기화
          if (self.search_page <= 1) {
            self.point_data = [];
          }

          for (let i = 0; i < data.length; i++) {
            self.point_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_point_data

    // 사용자 포인트 정보 가져오기
    load_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      if (this.storage.is_logged_in()) {
        this.axios
          .post(
            process.env.VUE_APP_API_URL + "/auth/get",
            {
              user_id: self.storage.get_user_id(),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            let data = response.data.data;
            self.user_point = data.user_point;

            // 기본 주문자 정보 설정
            self.point = data.user_point;
          })
          .finally(function () {
            self.process_queue.pop();
          });
      }
    }, //-- load_user_data

    // 검색 버튼 이벤트 핸들러
    click_search: function () {
      this.search_page = 1;
      this.data_fully_loaded = false;

      this.load_point_data();
    },

    convert_history_action_text: function (action) {
      switch (action.trim().toUpperCase()) {
        case "JOIN":
          return this.$t("mypage.point_join");
        case "SHOP":
          return this.$t("mypage.point_shop");
        case "BUY":
          return this.$t("mypage.point_buy");
        case "CANCEL":
          return this.$t("mypage.point_cancel");
        case "ADMIN":
          return this.$t("mypage.point_admin");
        case "REVIEW":
          return this.$t("mypage.point_review");
        case "EVENT":
          return this.$t("mypage.point_event");
        case "ETC":
          return this.$t("mypage.point_etc");
        case "JOIN_REFREE":
          return this.$t("mypage.point_join_refree");
        case "JOIN_REFERRER":
          return this.$t("mypage.point_join_referrer");
        case "BUY_REFREE":
          return this.$t("mypage.point_buy_refree");
        case "BUY_REFERRER":
          return this.$t("mypage.point_buy_referrer");
        default:
          return this.$t("mypage.point_etc");
      }
    },
  },
};
</script>

<style scoped>
.body {
}
.body > .search_wrap {
  padding: 1.2rem 1.6rem;
  margin: 0 0 1rem 0;
  border-radius: 0.8rem;
  background: #f4f4f4;
}
.body > .search_wrap > .status {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}
.body > .search_wrap > .status > .type {
  width: 25%;
  padding: 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
}
.body > .search_wrap > .status > .type.selected,
.body > .search_wrap > .status > .type:hover {
  background: #dedede;
}
.body > .search_wrap > .status > .type > h3 {
  font-size: 1.1rem;
  font-weight: 400;

  padding: 0;
  margin: 0 0 0.8rem 0;
}
.body > .search_wrap > .status > .type > img {
  height: 2.6rem;
}
.body > .search_wrap > .status > .type > .number {
  font-size: 2rem;
  font-weight: 300;
}

.body > .search_wrap > .date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.body > .search_wrap > .date > input[type="date"] {
  width: 35%;
}
.body > .search_wrap > .date > span {
  margin: 0 1rem;
}
.body > .search_wrap > .date > button.button {
  letter-spacing: 0;
  width: 20%;
  margin-left: 1rem;
  font-size: 1rem;
  padding: 0.8rem;
}

.body > .point_info {
  position: relative;
  margin: 0 0 1rem 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.body > .point_info > .title {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0 0.8rem 0 0;
}
.body > .point_info > .value {
  font-size: 1.2rem;
  font-weight: 700;
  color: #e74356;
}

.body > ul.point_list {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;

  border-top: 2px solid #333;
}
.body > ul.point_list > li {
  position: relative;
  padding: 0.4rem 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid #ccc;
}
.body > ul.point_list > li.empty {
  display: block;
  text-align: center;
  padding: 4rem 0;
}
.body > ul.point_list > li > .delta {
  width: 132px;
  max-width: 30%;
  padding: 1.2rem;
  margin: 0;

  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: #e74356;

  border-right: 1px solid #dedede;
}
.body > ul.point_list > li > .info {
  flex-grow: 1;
  padding: 1rem 1rem 1rem 2rem;
}
.body > ul.point_list > li > .info > .date {
  font-size: 1rem;
  color: #808080;
  margin: 0 0 0.3rem 0;
}
.body > ul.point_list > li > .info > .desc {
  font-size: 1rem;
  font-weight: 500;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}
</style>
